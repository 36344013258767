nav {
  width: 100%;
  background-color: #fbe5b0;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  transition: 1.7s;
}

.imgContainer {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  height: 60px;
  display: block;
}

nav ul li {
  display: inline;
  list-style: none;
  margin: 5px 20px;
  font-size: 16px;
}

.container {
  padding-left: 5%;
  padding-right: 5%;
}

.dark-nav {
  transition: 2s;
}


.menu-icon {
  display: none;
}

@media (max-width: 1000px) {
  .logo {
    width: 50px;
    height: 52px;
  }
  nav ul li {
    margin: 10px 15px;
  }
}

@media (max-width: 840px) {
  nav {
    padding: 15px 0;
  }
  .logo {
    width: 30px;
    height: 28px;
    margin-right: 10px;
  }
  nav h2{
    font-size: 18px;
  }
  nav ul {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgb(232, 232, 161);
    z-index: 1;
    width: 170px;
    padding-top: 70px;
    transition: 0.5s;
  }
  nav ul li {
    display: block;
    margin: 25px 40px;
  }
  .menu-icon {
    display: block;
    width: 30px;
    cursor: pointer;
    z-index: 1;
    margin-right: 20px;
  }
  .hide-mobile-menu {
    right: -570px;
  }
  .menu-icont {
    transform: rotate(90deg);
  }
}
