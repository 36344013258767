.tittle{
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    margin: 70px 0 30px;
}
.tittle h1{
    
    color: #000000;
    margin-top: 20px;
}
.tittle h2{
    
    margin-top: 20px;
}