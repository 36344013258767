.contact {
  margin: 80px auto;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-col {
  flex-basis: 48%;
  color: rgb(16, 25, 90);
}
.contact-col h3 {
  color: rgb(16, 25, 90);
  font-weight: 500;
  font-size: 25px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 20px;
}
.contact-col h3 img {
  width: 30px;
  margin-left: 20px;
}

.contact-col p {
  max-width: 450px;
  line-height: 1.3;
}

.contact-col ul li {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.contact-col ul li img {
  width: 30px;
  margin-right: 20px;
}

.contact-col ul li a {
  display: flex;
  align-items: center;
}
.contact form input, .contact form textarea{
  display: block;
  width: 100%;
  background: #dceaf4;
  padding: 15px;
  border: 0;
  outline: 0;
  margin-bottom: 15px;
  margin-top: 5px;
  resize: none;


}
.contact-col span{
  display: block;
  margin: 20px 0;
}
/* Media Query */
@media (max-width: 700px) {
  .contact{
    display: block;
  }
}

