.header {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
      rgba(250, 250, 250, 0.7),
      rgba(222, 221, 232, 0.7)
    ),
    url("../assets/headerimg.jpg") no-repeat center center;
  background-size: cover;
  color: rgb(0, 0, 0);
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-text {
  text-align: center;
  /* max-width: 900px; */
}
.header-text h1 {
  font-size: 50px;
  font-weight: 600;
}
.header-text p {
  max-width: 600px;
  margin: 25px auto 20px;
  line-height: 1.4;
}
.btn img{
    width: 20px;
    
}
/* media queriy */
@media (max-width: 850px) {
  .header-text h1{
    font-size: 40px;
  }
}
@media (max-width: 650px) {
  .header-text h1{
    font-size: 40px;
    max-width: 400px;
    margin: auto;
  }
}