.about{
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.about-left{
    flex-basis:40%;
}
.about-right{
    flex-basis: 56%;
}
.about-img{
    width: 100%;
    border-radius: 10px;
}
.about h3{
    font-weight: 600;
    font-size: 16px;
}
.about h2{
    font-size: 35px;
    margin: 10px 0;
    max-width: 400px;
}
.about p{
    margin-bottom: 15px; ;
}
/* mediaquery */
@media (max-width: 800px){
    .about{
        flex-direction: column;
    }
    .about-left{
        flex-basis: 100%;
        margin: 20px;
    }  
    .about-right{
        flex-basis: 100%;
    }
}