.pservices {
  margin: 20px auto;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* row-gap: 20px; */
  /* gap: 50px; */
}
.slick-slide {
  /* margin: 0 10px; */
}
.projectss.slick-track {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pservices img {
  width: 100%;
  border-radius: 10px;
  display: block;
  object-fit: cover;
  height: 100%;
}
.service {
  /* column-gap: 20px; */
  flex-basis: 40%;
  height: 50vh;
  min-height: 20vh;
}
.pservice {
  margin-left: 50px;
  /* width: 60%; */
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  margin-left: 20%;
  top: 50%;
  left: 50%;
  /* right: auto; */
  /* bottom: auto; */
  /* margin-right: -50%; */
  /* transform: translate(-50%, -50%); */
  max-width: 50%;
  max-height: 90%;
  z-index: 10;
  /* overflow: auto; */
}
/* Projects.css */
.modal-close-button {
  background-color: #ff5f5f; /* Red background */
  border: none; 
  color: white; 
  padding: 10px 20px; 
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  /* position: absolute; Position it absolutely */
  top: 10px; /* Position from the top */
  right: 10px; /* Position from the right */
}
.show-more-container .btn:hover {
  background-color: #89ebff; /* Darker blue on hover */
  color: black;
  transition: 0.4s;
}

