
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
/* @tailwind components; */
@tailwind utilities;
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}
body{
  background-color: white;
}

a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.btn{
  background-color:azure;
  padding: 14px 25px;
  border-radius: 30px;
  font-size: 16px;
  border: none;
  outline: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:1200px) {
    .container{
      padding-left: 5%;
      padding-right: 5%;
    }
    .btn{
      padding: 14px 22px;
    }
}