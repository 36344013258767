.slick-slide {
  margin: 0 10px;
}
button.slick-arrow.slick-next,
button.slick-arrow.slick-prev {
  background-color: rgb(30, 117, 182);
  padding-top: 2px;
  border-radius: 70%;
}
.sservices img {
  width: 100%;
  border-radius: 10px;
  display: block;
  object-fit: cover;
  height: 40vh;
}

.sservices {
  margin: 50px auto;
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

div.slick-track {
  /* width: 60px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sservice {
  column-gap: 20px;
  flex-basis: 40%;
  height: 100%;
  position: relative;
  /* min-height: 20vh; */
  /* width: 100%; */
}
.caption {
  color: white;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  opacity: 0;
  padding-top: 70%;
  transition: 0.4s;
}
.sservice:hover .caption {
  opacity: 1;
  padding-top: 0;
}
.slick-track {
  width: 50%;
  /* height: 80px; */
}
/* MEDIA QUERY */
@media (max-width: 860px) {
  .sservices{
    width: 94%;
  }
  .sservice .caption {
    opacity: 1;
    padding-top: 0;
  }
  
}